//require('modernizr');
//require('../styles/main.scss');

var $ = require('jquery');
var Handlebars = require('handlebars/dist/cjs/handlebars'); /*
	 This simple script converts XML (document of code) into a JSON object. It is the combination of 2
	 'xml to json' great parsers (see below) which allows for both 'simple' and 'extended' parsing modes.
	*/ /*
	 ### jQuery XML to JSON Plugin v1.3 - 2013-02-18 ###
	 * http://www.fyneworks.com/ - diego@fyneworks.com
		* Licensed under http://en.wikipedia.org/wiki/MIT_License
	 ###
	 Website: http://www.fyneworks.com/jquery/xml-to-json/
	*/ // Avoid collisions

/*
	 # INSPIRED BY: http://www.terracoder.com/
	           AND: http://www.thomasfrank.se/xml_to_json.html
												AND: http://www.kawa.net/works/js/xml/objtree-e.html
	*/ (function (
    $
) {
    // Add function to jQuery namespace
    $.extend({
        // converts xml documents and xml text to json object
        xml2json: function (xml, extended) {
            if (!xml) return {}; // quick fail

            //### PARSER LIBRARY
            // Core function
            function parseXML(node, simple) {
                if (!node) return null;
                var txt = '',
                    obj = null,
                    att = null;
                var nt = node.nodeType,
                    nn = jsVar(node.localName || node.nodeName);
                var nv = node.text || node.nodeValue || ''; //if(window.console) console.log(['x2j',nn,nt,nv.length+' bytes']);
                /*DBG*/ if (node.childNodes) {
                    if (node.childNodes.length > 0) {
                        /*DBG*/ //if(window.console) console.log(['x2j',nn,'CHILDREN',node.childNodes]);
                        $.each(node.childNodes, function (n, cn) {
                            var cnt = cn.nodeType,
                                cnn = jsVar(cn.localName || cn.nodeName);
                            var cnv = cn.text || cn.nodeValue || ''; //if(window.console) console.log(['x2j',nn,'node>a',cnn,cnt,cnv]);
                            /*DBG*/ if (cnt == 8) {
                                /*DBG*/ //if(window.console) console.log(['x2j',nn,'node>b',cnn,'COMMENT (ignore)']);
                                return; // ignore comment node
                            } else if (cnt == 3 || cnt == 4 || !cnn) {
                                // ignore white-space in between tags
                                if (cnv.match(/^\s+$/)) {
                                    /*DBG*/ //if(window.console) console.log(['x2j',nn,'node>c',cnn,'WHITE-SPACE (ignore)']);
                                    return;
                                } //if(window.console) console.log(['x2j',nn,'node>d',cnn,'TEXT']);
                                /*DBG*/ txt += cnv
                                    .replace(/^\s+/, '')
                                    .replace(/\s+$/, '');
                                // make sure we ditch trailing spaces from markup
                            } else {
                                /*DBG*/ //if(window.console) console.log(['x2j',nn,'node>e',cnn,'OBJECT']);
                                obj = obj || {};
                                if (obj[cnn]) {
                                    /*DBG*/ //if(window.console) console.log(['x2j',nn,'node>f',cnn,'ARRAY']);

                                    // http://forum.jquery.com/topic/jquery-jquery-xml2json-problems-when-siblings-of-the-same-tagname-only-have-a-textnode-as-a-child
                                    if (!obj[cnn].length)
                                        obj[cnn] = myArr(obj[cnn]);
                                    obj[cnn] = myArr(obj[cnn]);

                                    obj[cnn][obj[cnn].length] = parseXML(
                                        cn,
                                        true /* simple */
                                    );
                                    obj[cnn].length = obj[cnn].length;
                                } else {
                                    /*DBG*/ //if(window.console) console.log(['x2j',nn,'node>g',cnn,'dig deeper...']);
                                    obj[cnn] = parseXML(cn);
                                }
                            }
                        });
                    } //node.childNodes.length>0
                } //node.childNodes
                if (node.attributes) {
                    if (node.attributes.length > 0) {
                        /*DBG*/ //if(window.console) console.log(['x2j',nn,'ATTRIBUTES',node.attributes])
                        att = {};
                        obj = obj || {};
                        $.each(node.attributes, function (a, at) {
                            var atn = jsVar(at.name),
                                atv = at.value;
                            att[atn] = atv;
                            if (obj[atn]) {
                                /*DBG*/ //if(window.console) console.log(['x2j',nn,'attr>',atn,'ARRAY']);

                                // http://forum.jquery.com/topic/jquery-jquery-xml2json-problems-when-siblings-of-the-same-tagname-only-have-a-textnode-as-a-child
                                //if(!obj[atn].length) obj[atn] = myArr(obj[atn]);//[ obj[ atn ] ];
                                obj[cnn] = myArr(obj[cnn]);

                                obj[atn][obj[atn].length] = atv;
                                obj[atn].length = obj[atn].length;
                            } else {
                                /*DBG*/ //if(window.console) console.log(['x2j',nn,'attr>',atn,'TEXT']);
                                obj[atn] = atv;
                            }
                        });
                        //obj['attributes'] = att;
                    } //node.attributes.length>0
                } //node.attributes
                if (obj) {
                    obj = $.extend(
                        txt !== '' ? new String(txt) : {},
                        /* {text:txt},*/ obj || {} /*, att || {}*/
                    );
                    //txt = (obj.text) ? (typeof(obj.text)=='object' ? obj.text : [obj.text || '']).concat([txt]) : txt;
                    txt = obj.text ? [obj.text || ''].concat([txt]) : txt;
                    if (txt) obj.text = txt;
                    txt = '';
                }
                var out = obj || txt;
                //console.log([extended, simple, out]);
                if (extended) {
                    if (txt) out = {}; //new String(out);
                    txt = out.text || txt || '';
                    if (txt) out.text = txt;
                    if (!simple) out = myArr(out);
                }
                return out;
            } // parseXML
            // Core Function End
            // Utility functions
            var jsVar = function (s) {
                return String(s || '').replace(/-/g, '_');
            };

            // NEW isNum function: 01/09/2010
            // Thanks to Emile Grau, GigaTecnologies S.L., www.gigatransfer.com, www.mygigamail.com
            function isNum(s) {
                // based on utility function isNum from xml2json plugin (http://www.fyneworks.com/ - diego@fyneworks.com)
                // few bugs corrected from original function :
                // - syntax error : regexp.test(string) instead of string.test(reg)
                // - regexp modified to accept  comma as decimal mark (latin syntax : 25,24 )
                // - regexp modified to reject if no number before decimal mark  : ".7" is not accepted
                // - string is "trimmed", allowing to accept space at the beginning and end of string
                var regexp = /^((-)?([0-9]+)(([\.\,]{0,1})([0-9]+))?$)/;
                return (
                    typeof s == 'number' ||
                    regexp.test(
                        String(s && typeof s == 'string' ? jQuery.trim(s) : '')
                    )
                );
            }
            // OLD isNum function: (for reference only)
            //var isNum = function(s){ return (typeof s == "number") || String((s && typeof s == "string") ? s : '').test(/^((-)?([0-9]*)((\.{0,1})([0-9]+))?$)/); };

            var myArr = function (o) {
                // http://forum.jquery.com/topic/jquery-jquery-xml2json-problems-when-siblings-of-the-same-tagname-only-have-a-textnode-as-a-child
                //if(!o.length) o = [ o ]; o.length=o.length;
                if (!$.isArray(o)) o = [o];
                o.length = o.length;

                // here is where you can attach additional functionality, such as searching and sorting...
                return o;
            };
            // Utility functions End
            //### PARSER LIBRARY END

            // Convert plain text to xml
            if (typeof xml == 'string') xml = $.text2xml(xml);

            // Quick fail if not xml (or if this is a node)
            if (!xml.nodeType) return;
            if (xml.nodeType == 3 || xml.nodeType == 4) return xml.nodeValue;

            // Find xml root node
            var root = xml.nodeType == 9 ? xml.documentElement : xml;

            // Convert xml to json
            var out = parseXML(root, true /* simple */);

            // Clean-up memory
            xml = null;
            root = null;

            // Send output
            return out;
        },

        // Convert text to XML DOM
        text2xml: function (str) {
            // NOTE: I'd like to use jQuery for this, but jQuery makes all tags uppercase
            //return $(xml)[0];

            /* prior to jquery 1.9 */
            /*
            var out;
            try{
             var xml = ((!$.support.opacity && !$.support.style))?new ActiveXObject("Microsoft.XMLDOM"):new DOMParser();
             xml.async = false;
            }catch(e){ throw new Error("XML Parser could not be instantiated") };
            try{
             if((!$.support.opacity && !$.support.style)) out = (xml.loadXML(str))?xml:false;
             else out = xml.parseFromString(str, "text/xml");
            }catch(e){ throw new Error("Error parsing XML string") };
            return out;
            */

            /* jquery 1.9+ */
            return $.parseXML(str);
        }
    }); // extend $
})($);

$(document).ready(function () {
    // Debugging?
    var debugging = false;
    var baseURL =
        '/iviews/xformat/xformat.asp?contenttype=text%2Fxml&app-url=licence/getLicence&inputID=';
    var baseURLCSV =
        '/iviews/xformat/xformat.asp?contenttype=text%2Fxml&app-url=licence/zipDownload&inputID=';
    var baseURLextension = '&web-site=COM&web-lang=EN&xformat_reload=1';

    var hostname = $(location).attr('hostname');
    if (hostname == 'localhost' || hostname == 'litecom-int.zumtobel.com') {
        //alert("Debugging");
        debugging = true;
        baseURL =
            'https://litecom-int.zumtobel.com/iviews/xformat/xformat.asp?contenttype=text%2Fxml&app-url=licence/getLicence&inputID=';
        baseURLCSV =
            'https://litecom-int.zumtobel.com/iviews/xformat/xformat.asp?contenttype=text%2Fxml&app-url=licence/zipDownload&inputID=';
        //baseURL = "/server-endpoint/";
        //baseURLextension = ".xml";
    }

    _bindEventHandlers();

    // Templating app keys
    function templateAPPkeys(data) {
        var downloadCSVtext =
            '<span class="download-initial">Download Licence</span><span class="download-loading">Loading<span class="loader-dot">.</span><span class="loader-dot">.</span><span class="loader-dot">.</span></span><span class="download-done">Done</span>';
        //var source = '<section id="reference-{{reference}}" class="loading"><h2>{{reference}}</h2>{{#apps}}<div class="tile-outer"><div class="tile" data-clipboard-text="{{appLicenceKey}}"><small>{{appName}}</small><div class="reference-wrapper"><textarea name="activationKey" rows="2" readonly="">{{appLicenceKey}}</textarea><div class="clipboard-button"></div></div></div></div>{{/apps}}</section>';
        //var source = '{{#apps}}<section>{{#if projectID}}<h3>Infinity-ID: {{projectID}}</h3>{{/if}}<h2>Reference-Number (HW-ID): {{controllerID}}</h2><a class="download-csv" data-controllerID="{{controllerID}}">Download CSV</a>{{#app}}<div class="tile-outer"><div class="tile" data-clipboard-text="{{appLicenceKey}}"><small>{{appName}}</small><div class="reference-wrapper"><textarea name="activationKey" rows="2" readonly="">{{appLicenceKey}}</textarea><div class="clipboard-button"></div></div></div></div>{{/app}}</section>{{/apps}}';
        var source = `<section>
          <h2>
            {{#if INFID}}
              <span>Infinity ID</span>
              {{INFID}}
            {{else if CTREF}}
              <span>Hardware ID</span> {{CTREF}}
            {{/if}}
          </h2><a class="download-csv" data-inputID="{{request.inputID}}">${downloadCSVtext}</a>
          {{#item}}
              <div class="tile-outer">
                <div class="clipboard tile" data-clipboard-text="{{appLicenceKey}}">
                  {{#if APNAM}}
                    <small><span>App</span></small><small>{{APNAM}}</small>
                  {{else if CTNAM}}
                    <small><span>Controller</span> {{CTNAM}}</small><small><span>Hardware ID</span> {{CTREF}}</small>
                  {{/if}}
                  {{#if INFID}}
                    <small><span>Infinity ID</span> {{INFID}}</small>
                  {{/if}}
                  <div class="reference-wrapper">
                    <textarea name="activationKey" rows="2" readonly=„“>{{APLIC}}</textarea>
                    <div class="clipboard-button"></div>
                  </div>
                </div>
              </div>
            {{/item}}
          </section>`;
        var template = Handlebars.compile(source);
        var result = template(data);
        $('.wrapper > .inner').prepend(result);
        setTimeout(function () {
            $('.loading').removeClass('loading');
        }, 100);
        $('.tile').on('touchstart mousedown', function () {
            selectText($(this));
        });
    }

    // Templating errors
    function templateErrors(data) {
        var source;
        if (data.status == '404') {
            source =
                '<section id="reference-{{reference}}" class="loading"><h2>{{reference}}</h2><small class="error">No apps matching <i>{{reference}}</i> found.</small></section>';
        } else {
            source =
                '<section id="reference-{{reference}}" class="loading"><h2>{{reference}}</h2><small class="error">No APPs presently ordered</small></section>';
        }
        setTimeout(function () {
            $('.loading').removeClass('loading');
        }, 100);
        var template = Handlebars.compile(source);
        var result = template(data);
        $('.wrapper > .inner').prepend(result);
    }

    function downloadCSV(inputID, $this) {
        $.ajax({
            method: 'GET',
            url: baseURLCSV + inputID + baseURLextension,
            dataType: 'XML'
        })
            .done(function (msg) {
                if (debugging) {
                    console.log('XML received:');
                    console.dirxml(msg);
                }
                var data = $.xml2json(msg);
                if (debugging) {
                    console.log('Convert XML to JSON:');
                    console.dirxml(data);
                }
                //console.log(data);
                if (data.status === 'SUCCESS') {
                    zipURL = data.zipFile;
                    if (debugging) {
                        zipURL =
                            'http://litecom-int.zumtobel.com' + data.zipFile;
                    }
                    $this.addClass('loading');
                    setTimeout(function () {
                        document.getElementById('download-iframe').src = zipURL;
                        $this.removeClass('loading').addClass('done');
                    }, 2000);
                } else {
                    alert('Problem');
                }
                //templateAPPkeys(msg)
                //alert( "Data Saved: " + msg );
            })
            .fail(function (jqXHR, textStatus) {
                alert('Fail');
            });
    }

    function getAPPkeys(reference) {
        if ($('body').hasClass('starting')) {
            $('body').removeClass('starting').addClass('animate');
        }
        if (debugging) {
            console.log('baseURL: ' + baseURL + reference + baseURLextension);
        }
        $.ajax({
            method: 'GET',
            url: baseURL + reference + baseURLextension,
            dataType: 'XML'
        })
            .done(function (msg) {
                if (debugging) {
                    console.log('XML received:');
                    console.dirxml(msg);
                }
                var data = $.xml2json(msg);
                if (debugging) {
                    console.log('Convert XML to JSON:');
                    console.dirxml(data);
                }
                //console.log(data);
                if (data.status === 'SUCCESS') {
                    // Convert to propper array if length of apps = 1;
                    if (data.item.length === undefined) {
                        data.item = [data.item];
                    }

                    //templateAPPkeys(newData);
                    templateAPPkeys(data);
                    $('.download-csv').on('click', function () {
                        var inputID = $(this).attr('data-inputid');
                        downloadCSV(inputID, $(this));
                    });

                    $('body').removeClass('starting animate');
                    //console.log(apps);
                } else {
                    data = { reference: reference, status: data.status };
                    templateErrors(data);
                    console.log(data);
                }
                //templateAPPkeys(msg)
                //alert( "Data Saved: " + msg );
            })
            .fail(function (jqXHR, textStatus) {
                var data = { reference: reference, status: jqXHR.status };
                templateErrors(data);
                console.log(data);
            });
    }

    $('.input-search-api').keypress(function (event) {
        if ($('body').hasClass('starting')) {
            $('body').removeClass('starting').addClass('animate');
        } else {
            $('body').removeClass('animate');
        }
        $('.start-help').on(
            'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
            function () {
                $('body').removeClass('animate');
            }
        );
        if (event.keyCode == 13) {
            $('.submit-button').click();
        }
    });

    $('.submit-button').click(function () {
        $('body').removeClass('starting animate');
        var inputValue = $(this).next('input').val();
        getAPPkeys(inputValue);
    });

    $('.tile').click(function () {
        selectText();
    });

    function selectText(thisObj) {
        thisObj.addClass('pressed').one('touchend mouseup', function () {
            thisObj.removeClass('pressed');
            thisObj.find('textarea').select();
        });
    }
});

function _bindEventHandlers() {
    $(document).delegate('.clipboard', 'click', function () {
        const text = this.dataset.clipboardText;
        if (!text) return;
        navigator.clipboard.writeText(text);
    });
}
